import { Maybe, Query, SanityBlockContent } from "@graphql-types";
import { MessageType, PageContext } from "@util/types";
import { graphql, PageProps } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { useGetQuestions } from "@util/groqHooks";
import { Container, P } from "@util/standard";
import { Message, MessageResponse } from "@components";
import { BlocksContent, Button } from "@global";
import styled from "styled-components";
import { useTimeout } from "@util/hooks";

const StyledMessageWrapper = styled(Container)`
  ::-webkit-scrollbar {
    display: none;
  }
  justify-content: flex-start;
  padding-bottom: 50px;
  scroll-behaviour: smooth;
`;

const AbsolutePositionedContainer = styled(Container)`
  top: 10%;
  left: 3%;
  max-height: 630px;

  @media (max-width: 500px) {
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }
`;

const BackgroundWrapper = styled(Container)<{ backgroundImage?: string }>`
  ${({ backgroundImage }) =>
    backgroundImage &&
    `background-image: url(${backgroundImage}); background-size: contain; background-repeat: no-repeat;`}

  @media (max-width: 500px) {
    margin: 0px;
    background-image: none;
  }

  @media (max-height: 800px) {
    margin: 10px auto;
  }
`;

const StyledTopBar = styled(Container)`
  box-shadow: 0 2px 12px rgb(0 0 0 / 12%);
  display: fixed;
`;

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

const TextChatTemplate = ({ data, pageContext }: Props) => {
  const questions = data.allSanityTextChatQuestion.nodes;

  if (questions == null) return null;

  const { sanityTextChat, allSanitySiteConfig } = data;
  if (sanityTextChat == null) return null;

  const firstQuestion = questions.filter((question: any) => {
    return question._id == sanityTextChat.questions._id;
  });

  let defaultQuestion;

  if (sanityTextChat.startOption == "computer") {
    defaultQuestion = {
      ...firstQuestion[0],
      showDotsInitial: false,
    };
  } else {
    defaultQuestion = {
      answers: [
        {
          answer: sanityTextChat.initialQuestionPrompt,
          outcomeQuestion: firstQuestion[0],
          outcomeResult: null,
        },
      ],
      isInitial: true,
    };
  }

  const backgroundImage = allSanitySiteConfig.nodes[0].backgroundTextChatImage;

  const hasInitialScreen = sanityTextChat.introScreen != null;

  //Set states for prop drilling
  const [messageSet, setMessageSet] = useState<MessageType[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState(defaultQuestion);
  const [results, setResults] = useState<Maybe<SanityBlockContent> | undefined>(
    hasInitialScreen ? sanityTextChat.introScreen : null,
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollContainer, setScrollContainer] = useState(false);

  const [resultsVisible, setResultsVisible] = useState(false);

  useEffect(() => {
    setCurrentQuestion(defaultQuestion);
  }, [questions]);

  useEffect(() => {
    if (currentQuestion && !currentQuestion.isInitial) {
      setTimeout(() => {
        const newMessageSet = [
          ...messageSet,
          {
            message: currentQuestion.question,
            senderName: sanityTextChat.messageFromName,
            isReceiving: true,
            showDotsInitial: currentQuestion.showDotsInitial,
          },
        ];
        setMessageSet(newMessageSet);
      }, 3000);
    }
  }, [currentQuestion]);

  useEffect(() => {
    if (messageSet.length > 0) {
      containerRef.current?.scrollTo(0, containerRef.current?.scrollHeight);
    }
  }, [messageSet]);

  useEffect(() => {
    if (messageSet.length > 0 && scrollContainer) {
      setTimeout(() => {
        containerRef.current?.scrollTo(0, containerRef.current?.scrollHeight);
      }, 500);
    }
  }, [scrollContainer]);

  const handleResetChat = () => {
    setMessageSet([]);
    setCurrentQuestion(defaultQuestion);
    setResults(null);
  };

  return (
    <>
      <BackgroundWrapper
        margin="50px auto"
        width="400px"
        height="800px"
        position="relative"
        mobileWidth="100%"
        backgroundImage={backgroundImage?.asset?.url}
      >
        <AbsolutePositionedContainer
          width="356px"
          height="630px"
          backgroundColor="textChatBackground"
          flexDirection="column"
          justifyContent="space-between"
          display="flex"
          zIndex={10}
          position="relative"
          overflow="hidden"
        >
          {!results && (
            <>
              <StyledTopBar backgroundColor="textChatReceive" height="auto" zIndex={10}>
                <P
                  lineHeight={40}
                  fontSize={22}
                  fontFamily="cocon"
                  color="textChatTextColor"
                  margin="auto"
                >
                  {sanityTextChat.messageFromName}
                </P>
              </StyledTopBar>
              <Container
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
                overflow="unset"
              >
                <StyledMessageWrapper flexDirection="column" overflow="scroll" ref={containerRef}>
                  {messageSet &&
                    messageSet.map((message, index) => {
                      return (
                        <Message
                          key={index}
                          message={message.message}
                          senderName={message.senderName}
                          isReceiving={message.isReceiving}
                          showDotsInitial={message.showDotsInitial}
                        />
                      );
                    })}
                </StyledMessageWrapper>

                <MessageResponse
                  currentQuestion={currentQuestion}
                  messageSet={messageSet}
                  setMessageSet={setMessageSet}
                  setCurrentQuestion={setCurrentQuestion}
                  setResults={setResults}
                  setVisible={setResultsVisible}
                  questions={questions}
                  setShouldScrollContainer={setScrollContainer}
                />
              </Container>
            </>
          )}
          {/* results page */}
          {results && (
            <Container
              width="100%"
              height="900px"
              margin="auto"
              padding="30px"
              backgroundColor="cream"
              flexDirection="column"
              justifyContent="center"
              display={results ? "flex" : "none"}
            >
              <BlocksContent
                data={results}
                textAlign="center"
                setResults={setResults}
                margin="auto"
                customOnlick={() => handleResetChat()}
              />
            </Container>
          )}
        </AbsolutePositionedContainer>
      </BackgroundWrapper>
    </>
  );
};

export default TextChatTemplate;

export const query = graphql`
  query TextChatQuery($slug: String) {
    sanityTextChat(slug: { current: { eq: $slug } }) {
      ...sanityTextChat
    }
    allSanitySiteConfig {
      nodes {
        backgroundTextChatImage {
          asset {
            gatsbyImageData(width: 500, formats: AUTO, layout: CONSTRAINED)
            source {
              url
            }
            url
          }
        }
      }
    }
    allSanityTextChatQuestion(filter: { textChat: { slug: { current: { eq: $slug } } } }) {
      nodes {
        _type
        _id
        ...sanityTextChatQuestion
      }
    }
  }
`;
